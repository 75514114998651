import { ColorName, colors, Path, Spacing, Svg } from '@prontopro/ui-toolkit'
import React from 'react'

type IconProps = {
  fill: ColorName
  size: Spacing
}

export const HamburgerIcon = ({ fill, size }: IconProps) => (
  <Svg height={size} viewBox="0 0 16 16" width={size}>
    <Path d="M0 8.8h16V7.2H0zm0-6h16V1.2H0zm0 12h16v-1.6H0z" fill={colors[fill]} fillRule="evenodd" />
  </Svg>
)
