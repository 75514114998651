import { LinkButton } from '@pp/ui/LinkButton'
import { Flex } from '@prontopro/ui-toolkit'
import React from 'react'

export const NavLinks = () => (
  <Flex align="center" as="ul" direction="row" hide mdHide={false}>
    <Flex as="li" px={4}>
      <LinkButton to="https://www.prontopro.it/people/careers" kind="secondary">
        careers
      </LinkButton>
    </Flex>
  </Flex>
)
