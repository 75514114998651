import { Flex } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

type Props = { value: string }

const MaxWidth = styled(Flex).withConfig({
  shouldForwardProp: prop => !['value'].includes(prop),
})<Props>`
  max-width: ${props => props.value};
  margin: 0 auto;
  width: 100%;
`

export const MaxWidth1200 = styled(MaxWidth).attrs({
  value: '1200px',
})``

export const MaxWidth100Percent = styled(MaxWidth).attrs({
  value: '100%',
})``
