import { Flex } from '@prontopro/ui-toolkit'
import React from 'react'
import { Navbar } from './Navbar'
import { NavLinks } from './NavLinks'

export const Navigation = () => (
  <Flex as="nav" direction="row" justify="center" align="center">
    <NavLinks />
    <Navbar />
  </Flex>
)
