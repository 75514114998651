import { Flex } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

export const NavbarMenu = styled(Flex).attrs(() => ({
  background: 'black',
}))`
  height: 100%;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transform: translateY(0);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);

  &[hidden] {
    transform: translateY(-100%);
  }

  @media (min-width: 640px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 33%;
  }
`
