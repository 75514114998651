import { Flex } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

type Props = { value: string }

const MinHeight = styled(Flex).withConfig({
  shouldForwardProp: prop => !['value'].includes(prop),
})<Props>`
  min-height: ${props => props.value};
`

export const MinHeight100Vh = styled(MinHeight).attrs({
  value: '100vh',
})``
