import { Text } from '@prontopro/ui-toolkit'
import { ColorName } from '@prontopro/ui-toolkit/typings/tokens/colors'
import { Link as GatsbyLink } from 'gatsby'
import React, { ReactNode } from 'react'

type Props = {
  to: string
  children: string | ReactNode
  color?: ColorName
}

export const InternalLink = ({ to, children, color = 'black' }: Props) => (
  <GatsbyLink to={to}>
    <Text kind="bodyM" colorName={color} textDecoration="underline">
      {children}
    </Text>
  </GatsbyLink>
)
