import React, { ReactNode } from 'react'
import { Flex } from '@prontopro/ui-toolkit'

type Props = {
  children: ReactNode
}

export const Content = ({ children }: Props) => (
  <Flex py={32} px={24}>
    {children}
  </Flex>
)
