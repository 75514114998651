import { graphql, useStaticQuery } from 'gatsby'

export const useSiteData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            fbAppId
            siteUrl
            title
          }
        }
      }
    `,
  )

  return data
}
