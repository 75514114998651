import { ColorName, colors, Path, Spacing, Svg } from '@prontopro/ui-toolkit'
import React from 'react'

export type IconProps = {
  fill: ColorName
  size: Spacing
}

export const CloseIcon = ({ fill, size }: IconProps) => (
  <Svg height={size} viewBox="0 0 40 40" width={size}>
    <Path
      // eslint-disable-next-line max-len
      d="M25.6 14.3a1 1 0 0 1 0 1.4l-4.24 4.25 4.25 4.24a1 1 0 1 1-1.42 1.42l-4.24-4.25-4.24 4.25a1 1 0 0 1-1.42-1.42l4.25-4.24-4.25-4.24a1 1 0 0 1 1.42-1.42l4.24 4.25 4.24-4.25a1 1 0 0 1 1.42 0z"
      fill={colors[fill]}
      fillRule="evenodd"
    />
  </Svg>
)
