import { Logo } from '@pp/svg/Logo'
import { Flex } from '@prontopro/ui-toolkit'
import React from 'react'
import { MaxWidth1200 } from '../MaxWidth'
import { Navigation } from './Navigation'

export const Appbar = () => (
  <Flex as="header">
    <MaxWidth1200>
      <Flex align="center" basis="90px" direction="row" justify="space-between" px={24}>
        <Logo />
        <Navigation />
      </Flex>
    </MaxWidth1200>
  </Flex>
)
