import { Logo } from '@pp/svg/Logo'
import { Flex, Text } from '@prontopro/ui-toolkit'
import React from 'react'
import { MaxWidth1200 } from '../MaxWidth'
import { Navigation } from './Navigation'

export const Footer = () => (
  <Flex as="footer" background="black">
    <MaxWidth1200>
      <Flex px={24} pb={32} pt={40}>
        <Flex align="center" direction="row" justify="flex-start">
          <Logo fill="white" />

          <Navigation />
        </Flex>

        <Flex direction="row" justify="flex-start">
          <Text kind="bodyS" colorName="gray300">
            {`© 2019 - ${new Date().getFullYear()} ProntoPro`}
          </Text>
        </Flex>
      </Flex>
    </MaxWidth1200>
  </Flex>
)
