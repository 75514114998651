import { useSiteData } from '@pp/hooks/useSideData'
import shareImage from '@pp/static/images/share.jpg'
import { Location } from '@reach/router'
import React from 'react'
import Helmet from 'react-helmet'

type Props = {
  description?: string
  lang?: 'en'
  meta?: Array<
    | {
        name: string
        content: string
      }
    | {
        property: string
        content: string
      }
  >
  title: string
}

export const SEO = ({ description = '', lang = 'en', meta = [], title }: Props) => {
  const { site } = useSiteData()

  const metaDescription = description || site.siteMetadata.description

  return (
    <Location>
      {({ location }) => (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          meta={[
            {
              name: 'description',
              content: metaDescription,
            },
            {
              property: 'og:title',
              content: title,
            },
            {
              property: 'og:url',
              content: `${site.siteMetadata.siteUrl}${location.pathname}`,
            },
            {
              property: 'og:image',
              content: shareImage,
            },
            {
              property: 'fb:app_id',
              content: site.siteMetadata.fbAppId,
            },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:creator',
              content: site.siteMetadata.author,
            },
            {
              name: 'twitter:title',
              content: title,
            },
            {
              name: 'twitter:description',
              content: metaDescription,
            },
          ].concat(meta)}
        />
      )}
    </Location>
  )
}
