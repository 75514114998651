import { CloseIcon } from '@pp/svg/CloseIcon'
import { HamburgerIcon } from '@pp/svg/HamburgerIcon'
import { Logo } from '@pp/svg/Logo'
import { Flex } from '@prontopro/ui-toolkit'
import React, { useEffect, useCallback, useState } from 'react'
import { Content } from './Content'
import { NavbarMenu } from './NavbarMenu'

export const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    if (menuVisible) {
      body.style.overflow = 'hidden'

      return
    }

    body.style.overflow = ''
  }, [menuVisible])

  const toggleMenuVisibility = useCallback(() => {
    setMenuVisible(visible => !visible)
  }, [])

  return (
    <Flex>
      <Flex as="a" p={16} role="button" onClick={toggleMenuVisibility}>
        <HamburgerIcon fill="black" size={16} />
      </Flex>

      <NavbarMenu hidden={!menuVisible}>
        <Flex p={24}>
          <Flex direction="row" justify="space-between" mdJustify="flex-end" align="center">
            <Flex mdHide>
              <Logo fill="white" />
            </Flex>
            <Flex as="a" onClick={toggleMenuVisibility}>
              <CloseIcon size={64} fill="white" />
            </Flex>
          </Flex>
          <Flex pt={24} mdPl={12}>
            <Content />
          </Flex>
        </Flex>
      </NavbarMenu>
    </Flex>
  )
}
