import { Link } from 'gatsby'
import { Flex, Text } from '@prontopro/ui-toolkit'
import React from 'react'

export const Content = () => (
  <Flex align="flex-start">
    <Flex pb={12}>
      <Link to="/blog/">
        <Text kind="headingXS" colorName="white">
          Blog
        </Text>
      </Link>
    </Flex>
    <Flex pb={12}>
      <Link to="https://www.prontopro.it/people/careers">
        <Text kind="headingXS" colorName="white">
          Careers
        </Text>
      </Link>
    </Flex>
  </Flex>
)
