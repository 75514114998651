import { Button, Text } from '@prontopro/ui-toolkit'
import { ButtonKind } from '@prontopro/ui-toolkit/typings/components/Button/types'
import { Link } from 'gatsby'
import React from 'react'

type Props = {
  to: string
  kind: ButtonKind
  children: string
}

export const LinkButton = ({ to, kind, children }: Props) => (
  <Link to={to} target="_blank">
    <Button kind={kind} size="extra-small">
      <Text kind="bodySBold" colorName="darkGreen300" textTransform="uppercase">
        {children}
      </Text>
    </Button>
  </Link>
)
