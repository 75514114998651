import { Appbar } from '@pp/components/Appbar'
import { Footer } from '@pp/components/Footer'
import { Flex, GlobalStyle } from '@prontopro/ui-toolkit'
import React, { ReactNode } from 'react'
import { MinHeight100Vh } from '../MinHeight'

type Props = {
  children: ReactNode
}

export const Layout = ({ children }: Props) => (
  <>
    <GlobalStyle />

    <MinHeight100Vh>
      <Appbar />

      <Flex grow={1} shrink={1} as="main">
        {children}
      </Flex>

      <Footer />
    </MinHeight100Vh>
  </>
)
