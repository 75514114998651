import { Flex } from '@prontopro/ui-toolkit'
import { InternalLink } from '@pp/ui/InternalLink'
import React from 'react'

export const Navigation = () => (
  <Flex as="ul" direction="row" grow={1} justify="flex-end" pl={24}>
    <Flex as="li" px={8}>
      <InternalLink to="/blog/" color="white">
        Blog
      </InternalLink>
    </Flex>
    <Flex as="li" px={8}>
      <InternalLink to="https://www.prontopro.it/people/careers" color="lightBlue600">
        Careers
      </InternalLink>
    </Flex>
  </Flex>
)
